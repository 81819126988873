.familyPicture
{
  position: relative;
  height: calc(100% - 120px);
  top: -200px;
  padding: 0px 100px;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  .containerCircle
  {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .circle
    {
      position: absolute;
      display: block;
      z-index: 100;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.8s ease-in;
    }
    .circle.appearCircle
    {
      transform: scale(1);
    }
    :first-child
    {
      height: 251px;
      width: 251px;
      background-color: $colors-amber-500;
      right: -165px;
      top: -40px;
    }
    :nth-child(2)
    {
      height: 109px;
      width: 109px;
      background-color: $colors-dodger-blue-500;
      right: -69px;
      top: 171px;
    }
    :nth-child(3)
    {
      height: 144px;
      width: 144px;
      background-color: $colors-purple-500;
      left: -53px;
      bottom: -59px;
    }
  }

  .familyPictureImage
    {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
}

.familyMember
{
  position: relative;
  min-height: 680px;
  color: $colors-gray-500;
  background: linear-gradient(109.76deg, #FFDB6E 0%, #FFCC34 35.05%, #FFC107 62.9%);

  h2
  {
    @include titleLarge;
    padding-top: 80px;
    padding-left: 77px;
  }
  // .active
  // {
  //   opacity: 1!important;
  // }
  .memberBlock
  {
    @include labelBody;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(((100vw - 240px) * 5/12) *12);
    width: 100%;
    margin-top: 70px;
    color: $colors-gray-500;
    // &::before {
    //   content: '';
    //   position: fixed;
    //   left: calc(50% - (100vw - 240px) / 2 + 40px);
    //   top: calc(50% - 15vw);
    //   width: 30vw;
    //   height: 30vw;
    //   background-image: url("./assets/images/synerg-svg-amber300.svg");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: 80%;
    // }
    .member
    {
      position: sticky;
      display: flex;
      top: 0px;
      height: calc((100vw - 240px) * 5/12);
      .memberPicture
      {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-image: url("./assets/images/synerg-svg-amber300.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        img
        {
          width: 100%;
          object-fit: contain;
          transform: translateY(120px);
          will-change: transform;
        }
      }
      .memberText
      {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text
        {
          width: 95%;
          padding-top: 60px;
          .titleJobMember
          {
            margin-bottom: 4px;
          }
          .nameMember
          {
            @include titleMedium;
            margin-bottom: 15px;
          }
          .descriptionMember
          {
            margin-bottom: 20px;
          }
          >div
          {
            p
            {
              @include labelHighlight;
            }
            .memberPast
            {
              width: 97px;
              height: 97px;
              box-sizing: border-box;
              border: 3px solid $colors-purple-500;
              border-radius: 50%;
              overflow: hidden;
              margin-top: 5px;
              img
              {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }

      }
    }
    .member {
      opacity: 0;
      transform: translateY(40px);
      transition: opacity .7s $easing-synerg-calm, transform .7s $easing-synerg-calm;
      will-change: opacity, transform;
      &.active {
        opacity: 1;
        transform: translateY(0px);
        &.passed {
          opacity: 0;
          transform: translateY(-80px);
        }
      }
    }
  }
}

.board
{
  span {
    display: block;
  }
  .row .elem .content p,.row .elem .content p.details
  {
    @include labelHighlight;
    color: $colors-gray-500;
  }
  .row:nth-child(1) .elem .content
  {
    h2
    {
      @include titleMedium;
      color: $colors-purple-500;
    }
    p{
      @include labelBody;
      color: $colors-white;
    };
  }
  .row:nth-child(3)
  {
    text-align: center;
    // .elem:nth-child(3)
    // {
    //   p
    //   {
    //   }
    // }
  }
}

.closing
{
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(40px + (100vw - 240px) * 5/12);
  background-color: $colors-purple-500;
  p
  {
    @include titleLarge;
    color: $colors-white;
    margin-top: 40px;
  }
  p:first-child
  {
    @include labelBody;
    margin-top: 80px;
  }
}

@media screen and (max-width: 1240px) {
  .familyPicture {
    height: 240px;
    .containerPicture {
      img {
        width: 100%;
      }
    }
  }
  .familyMember
  {
    .memberBlock
    {
      .memberPicture
      {

      }
      .memberText
      {
        .text
        {
          padding-top: 0px !important;
          width: 90%;
          .titleJobMember
          {
            font-size: 18px;
          }
          .nameMember
          {
            font-size: 28px !important;
            line-height: 50px !important;
            margin-bottom: 0 !important;
          }
          .descriptionMember
          {
            font-size: 16px !important;
            line-height: 26px !important;
            margin-bottom: 8px !important;
          }

          >div
          {
            p
            {
              font-size: 20px;
            }
            .memberPast
            {
                height: 77px !important;
                width: 77px !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1025px){
  .familyPicture
  {
    .containerPicture
    {
      img
      {
      }
    }
  }
  .familyMember
  {
    h2
    {
      font-size: 46px;
      padding-top: 50px;
    }
    .memberBlock
    {
      height: calc(((100vw - 240px) * 8/12)*12);
      .member
      {
      height:calc((100vw - 240px) * 8/12);
        .memberPicture
        {
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 840px){
  .familyPicture
  {
    height: 260px;
    width: 100%;
    padding: 0;
    .containerPicture
    {
      width: 100%;
      top: -143px;
      img
      {
      }
    }
  }
  .familyMember
  {
    h2
    {
      font-size: 36px;
      padding-top: 20px;
      padding-left: 27px;
    }
    .memberBlock
    {
      margin-top: 10px;
      height: calc((100vh - 40px) *12);
      .member
      {
        height: calc(100vh - 40px);
        flex-direction: column;
      }
      .memberPicture
      {
        width: 100% !important;
        height: 50%;
        align-items: flex-end !important;
        background-position: bottom;
        background-size: 90%;
        position: relative;
        img
        {
          object-fit: contain;
          width: 60%!important;
          bottom: 110px;
          position: absolute;
        }
      }
      .memberText
      {
        position: relative;
        height: 50%;
        width: 100% !important;
        justify-content: flex-end !important;
        .text
        {
          width: 80% !important;
          .titleJobMember
          {
            font-size: 16px;
          }
          .nameMember
          {
            font-size: 28px;
            line-height: 50px;
          }
          .descriptionMember
          {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 8px;
          }
          p
          {
            font-size: 20px;
          }
          .memberPast
          {
            img
            {
              height: 77px;
              width: 77px;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 500px){

  .familyPicture
  {
    height: 40px;
    width: 100%;
    padding: 0;
    .containerPicture
    {
      width: 100%;
      top: -203px;
      img
      {
      }
    }
  }
  .familyMember
  {
    h2
    {
      width: 50%;
      font-size: 36px;
      padding-top: 20px;
      padding-left: 27px;
    }
    .memberBlock
    {
      margin-top: 10px;
      height: calc((100vh - 40px) *12);
      .member
      {
        height: calc(100vh - 40px);
        flex-direction: column;
      }
      .memberPicture
      {
        width: 100% !important;
        height: 50%;
        align-items: flex-end !important;
        background-position: bottom;
        background-size: 90%;
        position: relative;
        img
        {
          object-fit: contain;
          width: 55%!important;
          bottom: 200px;
          position: absolute;
        }
      }
      .memberText
      {
        position: relative;
        height: 50%;
        width: 100% !important;
        justify-content: flex-start!important;
        .text
        {
          width: 80% !important;
          .titleJobMember
          {
            font-size: 16px;
          }
          .nameMember
          {
            font-size: 28px;
            line-height: 50px;
          }
          .descriptionMember
          {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 8px;
          }
          p
          {
            font-size: 20px;
          }
          .memberPast
          {
            img
            {
              height: 77px;
              width: 77px;
            }
          }
        }
      }
    }
  }
}