.guestbook
{
    .quitContentView {
        position: fixed;
        margin-right: 0;
    }
    overflow: hidden;
    .selectDisable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    }

    button.slide-btn {
        display: inline-block;
        width: 255px;
        margin: 20px auto;
        color: $colors-gray-200;
        background-color: $colors-purple-500;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        @include labelBody;
        cursor: pointer;
        position: relative;
    }

    button.slide-btn::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .6s cubic-bezier(.19, 1, .22, 1);
        background-color: $colors-purple-100;
        opacity: .1;
        will-change: transform;
    }

    button.slide-btn:hover::after {
        transform: scaleX(1);
        transform-origin: left;
    }

    .container {
        width: 400%;
        height: 100%;
        display: flex;
        transform: translateX(0);
        transition: transform cubic-bezier(.19, 1, .22, 1) .6s;
        will-change: transform;

        &__home {
            height: 100%;
            width: calc(100% / 4);
            display: flex;
            justify-content: center;
            align-items: center;

            .content {
                text-align: center;
                width: 864px;

                @include media(1000px) {
                    width: 90%;

                    h1 {
                        margin: 30px auto;
                        font-size: 36px
                    }
                }

                &__title {
                    @include titleLarge;
                    font-family: 'Leitura Display';
                    font-style: italic;
                    color: $colors-purple-500;

                    
                }

                &__cta {
                    width: 584px;
                    margin: 50px auto;
                    
                    @include media(1000px) {
                        width: 90%;
                    }


                    &__icons {
                        width: 100%;
                        // margin: 0 20px;
                        display: flex;
                        justify-content: space-between;
                    }

                    .slidebar {
                        width: 100%;
                        height: 40px;
                        border-radius: 40px;
                        background-color: $colors-amber-300;
                        position: relative;
                        // padding: 0 20px;

                        .cursor {
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            background-color: $colors-amber-500;
                            position: absolute;
                            left: calc(50% - 48px / 2);
                            top: -4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // transform: scale(1);
                            // transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                            will-change: transform;
                            cursor: grab;

                            &:hover {
                                transform: scale(1.3);
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 23px;
                                height: 10px;
                                background: url('./assets/images/guestbook/cursor.svg');

                            }

                        }

                        .cta__text {
                            width: 90%;
                            margin: 0 auto;
                            height: 100%;
                            // padding: 0 30px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }


                }
            }
        }

        &__form {
            width: calc(100% / 4);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            textarea {
                // width: 100%;
                height: 60%;
                margin: 0 30px;
                padding: 10px;
                border: none;
                resize: none;
                outline: none;
                border-radius: 5px;
                background-color: $colors-gray-200;
                caret-color: $colors-purple-500;
                @include titleLarge;
                color: $colors-purple-500;
                position: relative;
            }

            // 
            


            textarea::placeholder {
                color: $colors-purple-300;
            }

            


        }
    }

    .container__recap {
        width: calc(100% / 4);
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
// }
