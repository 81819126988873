.contentView.retrospective {
    overflow: hidden;
    .titleBlock {
        height: calc(100% - (329px * 0.57));
    }
    &.running {
        overflow-y: auto;
    }
    .players {
        display: flex;
        align-items: center;
        width: (280px + 2 * $space-l ) * 11;
        transform: translateY(-50%);
        height: 33%;
        margin-bottom: 80px;
        will-change: transform;
    }
    h2:not(.title) {
        @include titleMedium;
        color: $colors-purple-500;
        margin-bottom: $space-xxl;
        span {
            display: block;
        }
    }
    section.mouvement {
        .bubblesGrid {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            .bubble {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 208px;
                height: 208px;
                margin: 0 $space-l $space-l;
                background-color: $colors-purple-100;
                p.number {
                    @include titleLarge;
                }
                p.details {
                    @include labelBody;
                    &.secondary {
                        color: $colors-gray-400;
                    }
                }
                .funBubblesBox {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0px;
                    height: 0px;
                    will-change: transform;
                    .funBubble {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: $colors-amber-500;
                        top: 0;
                        left: 0;
                        transform: translate(-50%, -50%);
                        &.purple {
                            background-color: $colors-purple-500;
                        }
                        &.blue {
                            background-color: $colors-dodger-blue-500;
                        }
                        &.small {
                            width: 24px;
                            height: 24px;
                        }
                        &.large {
                            width: 48px;
                            height: 48px;
                        }
                        &.p1 {
                            top: 80px;
                            left: 80px;
                        }
                        &.p2 {
                            top: -96px;
                            left: -32px;
                        }
                        &.p3 {
                            top: 40px;
                            left: -96px;
                        }
                        &.p4 {
                            left: 88px;
                            top: -56px;
                        }
                        &.p5 {
                            top: 64px;
                            left: -80px;
                        }
                    }
                }
            }
        }
    }
}

section.mouvement,
section.projects {
    padding: 0 2 * $space-xxl $space-xxl;
}
.projects {
    .project {
        display: flex;
        position: relative;
        border-radius: 20px;
        margin-bottom: 4 * $space-xxl;
        text-decoration: none;
        .content {
            flex: 1;
            padding: 2 * $space-xxl $space-xxl;
            h3 {
                @include titleLarge;
            }
            p {
                @include labelBody;
            }
        }
        .imgBox {
            position: relative;
            width: 55%;
            .imgBackground {
                position: absolute;
                width: 100%;
                top: $space-xxl;
                border-radius: 8px;
                will-change: transform;
                img {
                    position: relative;
                    width: 100%;
                    border-radius: 8px;
                    display: block;
                    bottom: $space-s;
                }
            }
        }
        &.right {
            margin-left: 2 * $space-xxl;
            margin-right: $space-xxl;
            background-color: $colors-amber-500;
            color: $colors-gray-500;
            .content {
                padding-right: 0;
            }
            .imgBackground {
                background: $colors-amber-500;
                right: -1 * $space-xxl;
                img {
                    right: $space-s;
                }
            }
        }
        &.left {
            margin-right: 2 * $space-xxl;
            margin-left: $space-xxl;
            background-color: $colors-purple-500;
            color: $colors-white;
            .content {
                padding-left: 0;
            }
            .imgBox {
                order: -1;
                .imgBackground {
                    left: -1 * $space-xxl;
                    background: $colors-purple-500;
                    img {
                        left: $space-s;
                    }
                }
            }
        }
    }
}

.playerCard {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    background-color: $colors-purple-100;
    margin: 0 $space-l;
    will-change: transform;
    img {
        width: 280px;
        height: 240px;
        border-radius: 20px 20px 0 0;
    }
    .description {
        padding: $space-m;
        .job {
            @include labelSecondary;
            color: $colors-gray-400;
            margin-left: $space-xs;
        }
        .name {
            @include titleSmall;
        }
    }
}

// Responsive
@media screen and (max-width: 1300px) {
    .contentView.retrospective {
        .titleBlock .title {
            margin: $space-xxl 0;
        }
    }
}
@media screen and (max-width: 1080px) {
    .contentView.retrospective {
        .titleBlock {
            height: calc(100% - (329px * 0.45));
            .title {
                margin: 56px 0;
            }
        }
    }
    .playerCard {
        img {
            height: 160px;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 1000px) {
    .contentView.retrospective {
        overflow-y: auto;
        .titleBlock {
            height: 320px;
            .title {
                margin: 80px 0;
            }
        }
        .players {
            flex-wrap: wrap;
            justify-content: space-evenly;
            height: auto;
            width: 100%;
            transform: translateY(-120px) !important;
            margin-bottom: -80px;
            .playerCard {
                margin-bottom: $space-xxl;
            }
        }
        section.mouvement,
        section.projects {
            padding: 0 $space-xxl $space-xxl;
        }
        .projects .project {
            &.right {
                margin-left: $space-xxl;
            }
            &.left {
                margin-right: $space-xxl;
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .contentView.retrospective {
        section.projects {
            padding: 0 0 $space-xxl;
            h2 {
                margin: 0 $space-l $space-xxl;
            }
            .project {
                background-color: transparent;
                margin: 0 !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                .imgBox {
                    order: 0;
                    width: 80%;
                    max-width: 350px;
                    transform: translateY(56px);
                    .imgBackground {
                        position: static;
                    }
                }
                &.right .content {
                    background-color: #FFC107;
                }
                &.left .content {
                    background-color: #59089E;
                }
                .content {
                    order: 2;
                    padding: 80px 40px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }
}