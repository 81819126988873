//AMBER
$colors-amber-500: #FFC107;
$colors-amber-300: #FEEEBA;
$colors-amber-100: #FFFCF0;

//PURPLE
$colors-purple-500: #59089E;
$colors-purple-300: #DED5EA;
$colors-purple-100: #f4f1f9;

//DODGER BLUE
$colors-dodger-blue-500: #42A5F5;
$colors-dodger-blue-300: #CDE6FE;

//GREEN
$colors-green-500: #81D155;

//GRAY
$colors-gray-500: #2B1E31;
$colors-gray-400: #94969C;
$colors-gray-300: #E7E7E7;
$colors-gray-200: #F9F9F9;
$colors-white: #FFFFFF;