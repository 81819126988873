.contentView.bottle {
    display: flex;
    flex-direction: column;
    top: auto;
    bottom: $space-xxl;
    height: auto;
    max-height: calc(50vh - 40px);
    background: $colors-purple-100;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./assets/images/logo-clair.svg');
    background-size: 80% auto;
    .quitContentView {
        position: static;
        margin-top: $space-l;
    }
    .message__container {
        flex: 1;
        margin: $space-xxl $space-xxl * 3;
        display: flex;
        flex-direction: column;
        @include titleSmall;
        .message {
            flex: 1;
        }
        .signature {
            color: $colors-purple-500;
            display: flex;
            justify-content: flex-end;
            margin-top: $space-xl;
            margin-bottom: $space-xxl;
        }
    }
}

@include media(1000px) {
    .contentView.bottle {
        bottom: 0;
        max-height: 60vh;
    }
}

@include media(840px) {
    .contentView.bottle .message__container {
        margin: $space-xxl 2 * $space-xxl;
    }
}

@include media(600px) {
    .contentView.bottle .message__container {
        margin: $space-xxl;
    }
}