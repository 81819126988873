#intro
{
  z-index: 100;
  display: none;
  width: 100%;
  height: 100%;
  .skipIntro
  {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    font-family: "Geomanist Medium", sans-serif;
    font-style: normal;
    font-size: 18px;
    color: #DED5EA;
    right: 20px;
    z-index: 150;
    cursor: pointer;
    opacity: 0;
  }
  .skipIntro::after
  {
    content: "";
    display: block;
    width: 25%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url('./assets/images/icon/next.svg');
  }
  #introCanvas
  {
    position: absolute;
    top: 0;
    z-index: 99;
    background: $colors-gray-500;
  }
  .introText
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    >div
    {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 70%;
      opacity: 0;
      p
      {
        @include bodyIntro;
        text-align: center;
        span
        {
          @include titleDiscours;
        }
        span.purpleTouch
        {
          font-family: "Geomanist Medium", sans-serif;
          font-style: normal;
        }
      }
      #enjoy
      {
        position: relative;
        border: 1px solid $colors-purple-100;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        transition: border-color .4s $easing-synerg-calm, opacity 0.8s $easing-synerg-calm;
      }
      #enjoy span
      {
        @include labelBody;
        font-style: normal;
        display: flex;
        justify-content: center;
        color: $colors-purple-100;
        padding: 15px 59px;
        z-index: 5;
        transition: color .4s $easing-synerg-calm;
      }
      #enjoy::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $colors-purple-100;
        z-index: -1;
        left: 0px;
        transition: transform .4s $easing-synerg-calm;
        transform: translateY(100%);
      }
      #enjoy span::after
      {
        content: '';
        height: 27px;
        width: 27px;
        display: block;
        margin-left: 16px;
        background-image: url('./assets/images/icon/heart.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      #enjoy:hover::before
      {
        filter: brightness(1.4);
        transform: translateY(0%);
      }
      #enjoy:hover
      {
        border-color: $colors-purple-500;
      }
      #enjoy:hover span
      {
        color: $colors-purple-500;
      }
      #enjoy:hover span::after
      {
        background-image: url('./assets/images/icon/heartPurple.svg');
      }
    }
    .textOne
    {
      z-index: 2;
      color: $colors-purple-300;
      a,p
      {
        opacity: 0;
      }
    }
    .textTwo
    {
      justify-content: center;
      height: 57%;
      .introLogo
      {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          transform: scale(1)
        }
      }
      #introLogoName {
        height: 56px;
      }
      p
      {
        @include titleLarge;
        color: $colors-purple-500;
        margin-top: 8px;
      }
    }
    .textThree
    {
      justify-content: center;
      p
      {
        @include titleLarge;
        color: $colors-purple-100;
      }
      :first-child
      {
        @include labelBody
      }
    }
    .textFour
    {
      align-items: stretch;
      padding: 0 200px;
      box-sizing: border-box;
      opacity: 1;
      p
      {
        opacity: 0;
        @include titleMedium;
        color: $colors-purple-100;
      }
      .leftText {
        text-align: left;
      }
      .rightText {
        text-align: right;
      }
    }
  }
  .outDown
  {
    opacity: 0!important;
    transform: translateY(40px);
    transition: transform  .4s $easing-synerg-calm, opacity .3s $easing-synerg-calm;
  }
  .scaleUp
  {
    transform: scale(1)!important;
    transform-origin: center;
    transition: transform  0.6s $easing-synerg-in;
  }
}
.appearDisplay
{
  display: block!important;
}

@include media(400px) {
  #intro
  {
    .introText
    {
      >div
      {
        p
        {
          font-size: 16px;
          line-height: 32px;
          span
          {
            font-size: 18px;
            line-height: 34px;
          }
          span.purpleTouch
          {
            font-size: 18px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

@include media(680px) {
  #intro
  {
    .introText
    {
      >div
      {
        p
        {
          font-size: 18px;
          line-height: 34px;
          span
          {
            font-size: 20px;
            line-height: 36px;
          }
          span.purpleTouch
          {
            font-size: 20px;
            line-height: 36px;
          }
        }
      }
    }
  }
}
@include media(840px) {
  #intro .introText .textFour {
    padding: 0 80px;
  }
}

@include media(520px) {
  #intro .introText .textFour {
    padding: 0 40px;
  }
}

@include media(370px) {
  #intro .introText .textFour {
    padding: 0 20px;
  }
}