#gui {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $space-l $space-l $space-xxl $space-l;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    & > div,
    & > a {
        will-change: opacity;
        transition: opacity .4s $easing-synerg-calm;
    }
    &.hidden {
        & > div,
        & > a {
            opacity: 0;
        }
    }
    &.userHasAlreadyInteract .onboardingRow {
        transform: translateY(40px);
        opacity: 0;
    }
    .headerRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .compass {
            .row {
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-of-type .compassIndicator {
                    margin-bottom: 0
                }
                .compassIndicator {
                    @include titleSmall;
                    color: $colors-purple-300;
                    margin: 8px;
                }
            }
            .indicator {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                border: 4px solid $colors-purple-300;
                display: flex;
                align-items: center;
                justify-content: center;
                .arrow {
                    width: 66.66%;
                    will-change: transform;
                }
            }
        }
        a.shareButton {
            width: 48px;
            height: 48px;
            background: url('./assets/images/icon/share.svg') center;
            background-size: 100%;
            margin-right: $space-m;
        }
    }
    .onboardingRow {
        display: flex;
        align-items: center;
        justify-content: center;
        will-change: transform, opacity;
        transition: opacity .4s .1s ease-out, transform .5s ease-out;
        &.touchscreen {
            display: none;
            .gameMode {
                width: 100%;
            }
        }
        .gameMode {
            padding: $space-xl 0;
            width: 320px;
            text-align: center;
            &:nth-of-type(2) {
                border-left: 2px solid $colors-purple-300;
            }
            img {
                height: 88px;
            }
            p {
                @include labelBody;
                color: $colors-purple-300;
            }
        }
    }
    .audioButton {
        position: absolute;
        right: 40px;
        bottom: 40px;
        &.paused .audioWave .wave {
            animation-play-state: paused;
        }
        .audioWave {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .wave {
                height: 24px;
                width: 2px;
                border-radius: 1px;
                background: $colors-purple-300;
                animation: audioWave 1s ease-in-out infinite alternate;
            }
            @for $i from 1 through 9 {
                .wave:nth-of-type(#{$i}) {
                    animation-delay: 0.3s*$i;
                }
            }
        }
    }
    a.button {
        cursor: pointer;
        will-change: transform;
        transition: transform .2s ease-in-out;
        &:hover {
            transform: rotate(4deg) scale(1.1);
        }
    }
    .islandPanel {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 20;
        left: 80px;
        right: 80px;
        bottom: 0;
        text-align: center;
        transform: translateY(80px);
        opacity: 0;
        will-change: transform, opacity;
        transition: transform 0.6s $easing-synerg-in, opacity 0.6s $easing-synerg-in;
        &.open {
            transform: translateY(0px);
            opacity: 1;
            transition: transform 0.6s $easing-synerg-out, opacity 0.6s $easing-synerg-out;
        }
        .keyboardInfo {
            @include labelBody;
            padding: $space-m;
            color: $colors-purple-300;
            .keyboardKey {
                padding: $space-s $space-s $space-xs;
                margin: 0 $space-xs;
                border: 2px solid $colors-purple-300;
                border-radius: 4px;
            }
        }
        .box {
            position: relative;
            width: 800px;
            max-width: calc(100vw - 160px);
            will-change: transform;
            transition: transform 0.3s $easing-synerg-out;
            h2 {
                @include titleMedium;
                border-radius: 40px 40px 0 0;
                background-color: $colors-purple-100;
                color: $colors-purple-500;
                padding: $space-xxl $space-xxl $space-l;
            }
            a,
            a::after {
                @include labelHighlight;
                display: block;
                height: 24px;
                padding: $space-l $space-xxl;
                text-decoration: none;
            }
            a {
                background-color: $colors-purple-500;
                color: $colors-purple-100;
                position: absolute;
                z-index: -1;
                top: 100%;
                left: 0;
                right: 0;
                will-change: transform;
                transition: transform 0.2s cubic-bezier(.79,.14,.15,.86);
                &::after {
                    content: 'Découvrir cette île';
                    background-color: $colors-purple-500;
                    color: $colors-purple-100;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                }
                &:hover {
                    transform: translateY(-72px);
                }
            }
        }
        &:hover .box {
            transform: translateY(-72px);
            transition: transform .3s $easing-synerg-in;
        }
    }
}

.contentView {
    position: fixed;
    top: 40px;
    left: calc(50% - (100vw - 240px) / 2);
    z-index: 1;
    width: calc(100vw - 240px);
    height: calc((100vw - 240px) * 5/12);
    border-radius: 40px;
    background-color: $colors-gray-200;
    opacity: 0;
    transform: translateY(80px);
    will-change: transform, opacity;
    scroll-behavior: smooth;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &.open {
        z-index: 100;
        opacity: 1;
        transform: translateY(0px);
        transition: opacity .6s ease-in-out, transform .6s ease-in-out;
        &.loaded {
            transition: none;
        }
    }
    .quitContentView {
        @include labelAction;
        cursor: pointer;
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        right: 40px;
        margin-right: 40px;
        top: 24px;
        z-index: 100;
        color: $colors-gray-500;
        &::after {
            content: '';
            display: block;
            width: 32px;
            height: 32px;
            text-align: center;
            background: url('./assets/images/icon/cross.svg') center;
        }
    }
    .titleBlock {
        position: relative;
        top: -32px;
        display: flex;
        justify-content: center;
        height: 360px;
        background-color: $colors-purple-500;
        color: $colors-white;
        .title {
            @include titleLarge;
            margin: 80px 0;
            text-align: center;
        }
    }
}

.contentView::-webkit-scrollbar
{
    display: none;
}

@keyframes audioWave {
    from { transform: scaleY(1); }
    to { transform: scaleY(0.2); }
}

// Responsive
@media screen and (min-width: 1600px) {
    .contentView {
        left: calc(50% - (100vw - 480px) / 2);
        width: calc(100vw - 480px);
        height: calc((100vw - 480px) * 5/12);
    }
}

@media screen and (min-width: 2000px) {
    .contentView {
        left: calc(50% - (100vw - 800px) / 2);
        width: calc(100vw - 800px);
        height: calc((100vw - 800px) * 5/12);
    }
}

@media screen and (max-width: 1240px) {
    .contentView {
        left: calc(50% - (100vw - 160px) / 2);
        width: calc(100vw - 160px);
        height: calc((100vw - 160px) * 5/12);
    }
}

@media screen and (max-width: 1080px) {
    .contentView {
        left: calc(50% - (100vw - 80px) / 2);
        width: calc(100vw - 80px);
        height: calc((100vw - 80px) * 5/12);
    }
    #gui .headerRow .compass .indicator {
        width: 96px;
        height: 96px;
    }
}

@media screen and (max-width: 1000px) {
    .contentView {
        height: calc(100vh - 40px) !important;
        width: 100vw;
        left: 0px;
        border-radius: 40px 40px 0 0;
        .quitContentView {
            top: 16px;
            right: 24px;
            margin-right: 24px;
        }
    }
    #gui .islandPanel {
        left: 0;
        right: 0;
        .box {
            width: 100%;
            max-width: none;
        }
    }
}

@media screen and (max-width: 520px) {
    #gui .headerRow .compass {
        .indicator {
            width: 80px;
            height: 80px;
        }
        .row .compassIndicator {
            display: none;
        }
    }
}

@media screen and (pointer: coarse) {
    #gui {
        .onboardingRow {
            &.computer {
                display: none;
            }
            &.touchscreen {
                display: block;
            }
        }
        .islandPanel {
            &.open {
                transform: translateY(-72px) !important;
            }
            .keyboardInfo {
                display: none;
            }
        }
    }
}