@mixin media($width) {
    @media only screen and (max-width: $width) {
      @content;
    }
  }

@import 'styles/fonts.scss';
@import 'styles/colors.scss';
@import 'styles/easings.scss';
@import 'styles/spacing.scss';
@import 'styles/intro.scss';
@import 'styles/gui.scss';

@import 'styles/pages/retrospective.scss';
@import 'styles/pages/speech.scss';
@import 'styles/pages/new-mandate.scss';
@import 'styles/pages/components/StatsGrid.scss';
@import 'styles/pages/customization.scss';
@import 'styles/pages/guestbook.scss';
@import 'styles/pages/bottle.scss';

*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    @include labelBody;
    overflow: hidden;
    height: 100%;
    width: 100%;
    color: $colors-gray-500;
}

.appear
{
    opacity: 1!important;
    transition: opacity 0.8s $easing-synerg-calm
}
.disappear
{
    opacity: 0!important;
    transition: opacity 0.8s $easing-synerg-calm
}

.appear-long
{
    opacity: 1!important;
    transition: opacity 3.5s $easing-synerg-calm
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
    opacity: 0;
    &.debug {
        z-index: 200;
    }
}

@media screen and (max-width: 1080px) {
    #awwwards {
        display: none;
    }
}
