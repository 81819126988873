.contentView.speech {
    .quitContentView {
        position: fixed;
        margin-right: 0;
    }
    section.speech {
        display: flex;
        height: 100%;
        overflow: hidden;
        .speakers {
            height: 100%;
            margin-left: $space-xxl;
            .speakersBox {
                will-change: transform;
                &.animated {
                    transition: transform .6s $easing-synerg-in;
                }
                .playerCard {
                    margin: $space-l;
                    transform: rotate(-10deg);
                    opacity: 0.5;
                    will-change: opacity, transform;
                    transition: transform .3s $easing-synerg-calm, opacity .3s $easing-synerg-calm;
                    &.before,
                    &.after {
                        cursor: pointer;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    &.before:hover {
                        transform: rotate(-10deg) translateY(16px);
                    }
                    &.after:hover {
                        transform: rotate(-10deg) translateY(-16px);
                    }
                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
        .content {
            position: relative;
            flex: 1;
            height: calc(100% - #{$space-xxl});
            margin: 0 $space-xxl $space-xxl;
            overflow: hidden;
            &::before,
            &::after {
                content: '';
                pointer-events: none;
                width: 100%;
                height: 3 * $space-xxl;
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                background: linear-gradient(rgba($colors-gray-200, 1), rgba($colors-gray-200, 0.7), rgba($colors-gray-200, 0));
            }
            &::before {
                top: 0;
            }
            &::after {
                transform: rotate(180deg);
                bottom: $space-xxl;
            }
            .scrollingBox {
                transform: translateY(120px);
                will-change: transform;
                transition: transform .6s $easing-synerg-calm;
                .paragraphBox {
                    height: calc((100vw - #{6 * $space-xxl}) * 5/12 - #{6 * $space-xxl} - #{2 * $space-l});
                    display: flex;
                    align-items: center;
                    &.current p {
                        color: $colors-purple-500;
                    }
                    p {
                        @include titleDiscours;
                        color: $colors-gray-200;
                        -webkit-text-stroke: 1px $colors-purple-500;
                    }
                }
            }
            .player {
                position: absolute;
                z-index: 10;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding-right: $space-xxl;
                height: 44px;
                background-color: $colors-gray-200;
                .progressBar {
                    position: relative;
                    background: $colors-purple-300;
                    height: 8px;
                    flex: 1;
                    border-radius: 4px 0 0 4px;
                    .progression {
                        background-color: $colors-purple-500;
                        height: 100%;
                        width: 100%;
                        border-radius: 4px;
                        transform-origin: left center;
                        transform: translateX(-100%);
                        &::after {
                            content: '';
                            position: absolute;
                            top: -50%;
                            right: -4px;
                            height: 200%;
                            width: 8px;
                            background-color: $colors-purple-500;
                            border-radius: 4px;
                        }
                    }
                }
                .button {
                    cursor: pointer;
                    width: 44px;
                    height: 44px;
                    background-color: $colors-purple-500;
                    border-radius: 4px;
                    &:last-of-type {
                        margin-left: $space-s;
                    }
                    &.playPauseButton {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.paused {
                            &::before {
                                transform: rotate(90deg) translateX(-5px) translateY(-10px);
                            }
                            &::after {
                                transform: rotate(90deg) translateX(5px) translateY(10px);
                            }
                        }
                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            border-radius: 1px;
                            width: 4px;
                            height: 20px;
                            margin: $space-xs;
                            background: $colors-gray-200;
                            will-change: transform;
                            transition: transform .3s $easing-synerg-in;
                        }
                    }
                    &.nextButton {
                        background: url('./assets/images/icon/next.svg') no-repeat center;
                        background-size: 100%;
                        background-color: $colors-purple-500;
                    }
                }
            }
        }
    }
}

// Responsive
@media screen and (max-width: 1320px) {
    .contentView.speech section.speech {
        .speakers {
            margin-left: $space-l;
            transform: scale(0.8);
            transform-origin: left center;
        }
        .content {
            margin: 0 $space-l $space-xxl -24px;
            .scrollingBox {
                .paragraphBox {
                    height: calc((100vw - 240px) * 5/12 - 160px - 48px); // Décalage intial de 80px
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .contentView.speech section.speech {
        .speakers {
            transform: scale(0.7);
        }
        .content {
            margin: 0 $space-l $space-xxl -72px;
            .scrollingBox {
                .paragraphBox {
                    height: calc((100vw - 240px) * 5/12 - 120px - 48px); // Décalage intial de 60px
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .contentView.speech section.speech {
        flex-direction: column;
        .speakers {
            height: auto;
            transform: none;
            margin: $space-xxl 0 0;
            z-index: 10;
            .speakersBox {
                display: flex;
                width: (280px + 32px) * 3;
                .playerCard {
                    transform: none !important;
                    margin: $space-m $space-m 0;
                }
            }
        }
        .content {
            margin: -40px $space-xxl $space-xxl;
            .scrollingBox {
                .paragraphBox {
                    height: calc(100vh - 40px - 254px - 240px);
                }
            }
            .player {
                padding-right: 0;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contentView.speech section.speech {
        .speakers .speakersBox .playerCard {
            margin: $space-m $space-s 0;
        }
        .content {
            margin: -40px $space-l $space-l;
        }
    }
}