.statsGrid {
    + section {
        margin-top: 2 * $space-xxl;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        &.subRow {
            width: 50%;
            div.elem {
                padding-bottom: 50%;
                &.square {
                    width: 50%;
                }
            }
        }
        div.elem {
            position: relative;
            padding-bottom: 25%;
            overflow: hidden;
            &.visible {
                .content,
                &::after {
                    opacity: 1;
                    transform: translateY(0%);
                }
                &.image::after {
                    transform: translateY(-100%);
                }
                &.passed {
                    .content,
                    &::after {
                        transform: translateY(-100%);
                    }
                }
            }
            &.delay {
                .content {
                    transition-delay: .75s;
                }
                &::after {
                    transition-delay: .6s;
                }
            }
            &::after {
                content: '';
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateY(100%);
                will-change: transform;
                transition: transform 0.7s $easing-synerg-calm;
            }
        }
        .elem {
            object-fit: cover;
            .content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: $space-xxl;
                display: flex;
                justify-content: center;
                flex-direction: column;
                transform: translateY(100%);
                opacity: 0;
                will-change: transform, opacity;
                transition: transform 0.7s $easing-synerg-calm, opacity 0.7s $easing-synerg-calm;
                transition-delay: .15s;
                h2 {
                    @include titleMedium;
                    color: $colors-purple-500;
                    margin-bottom: 0;
                    span {
                        display: block;
                    }
                }
                p.quote {
                    @include labelHighlight;
                }
                p.number {
                    @include titleLarge;
                }
                p.details {
                    @include labelBody;
                }
            }
            &.image {
                .content {
                    padding: 0;
                    opacity: 1;
                    transform: none;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                &::after {
                    z-index: 1;
                    transform: translateY(0%);
                }
            }
            &.banner {
                width: 50%;
            }
            &.square {
                width: 25%;
            }
            &.largeSquare {
                width: 50%;
                padding-bottom: 50%;
            }
            &.purple {
                .content {
                    color: $colors-white;
                }
                &::after {
                    background-color: $colors-purple-500;
                }
            }
            &.amber::after {
                background-color: $colors-amber-500;
            }
            &.blue::after {
                background-color: $colors-dodger-blue-500;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .statsGrid {
        .row {
            &.subRow {
                width: 100%;
                div.elem {
                    padding-bottom: 50%;
                    &.square {
                        width: 50%;
                    }
                }
            }
            div.elem {
                padding-bottom: 50%;
            }
            .elem {
                &.banner {
                    width: 100%;
                }
                &.square {
                    width: 50%;
                }
                &.largeSquare {
                    width: 100%;
                    padding-bottom: 50%;
                }
            }
        }
    }
}