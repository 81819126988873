.contentView.customization {
    height: auto;
    .header {
        display: flex;
        justify-content: space-between;
        padding: $space-l $space-xxl;
        h2 {
            @include titleSmall;
        }
    }
    .customizationPointsBox {
        display: flex;
        padding: $space-s 0 $space-xxl;
        .customizationPoint {
            padding: $space-xl;
            &:nth-of-type(2) {
                border-right: 2px solid $colors-purple-300;
                border-left: 2px solid $colors-purple-300;
            }
            h3 {
                @include labelHighlight;
            }
            p {
                @include labelSecondary;
                color: $colors-gray-400;
                padding: $space-s 0 $space-m;
            }
            .pickerBox {
                display: flex;
                justify-content: space-between;
                .color {
                    cursor: pointer;
                    height: 32px;
                    width: 24px;
                    border-radius: 4px;
                    opacity: 0.2;
                    &.current,
                    &:hover {
                        opacity: 1;
                    }
                    &.purple { background-color: $colors-purple-500; }
                    &.amber { background-color: $colors-amber-500; }
                    &.blue { background-color: $colors-dodger-blue-500; }
                    &.green { background-color: $colors-green-500; }
                    &.red { background-color: #D15555; }
                    &.pink { background-color: #C055D1; }
                    &.white { background-color: $colors-white; border: 1px solid $colors-gray-400; }
                    &.watergreen { background-color: #42F5CA; }
                    &.grey-300 { background-color: $colors-gray-300; }
                    &.grey-400 { background-color: $colors-gray-400; }
                    &.grey-500 { background-color: $colors-gray-500; }
                    &.blue-300 { background-color: $colors-dodger-blue-300; }
                    &.amber-300 { background-color: $colors-amber-300; }
                    &.purple-300 { background-color: $colors-purple-300; }
                }
                button.flagInputButton {
                    cursor: pointer;
                    @include labelSecondary;
                    color: $colors-purple-500;
                    border: 1px solid $colors-purple-500;
                    border-radius: 4px;
                    padding: $space-s $space-m $space-xs;
                    -webkit-appearance: none;
                    background: transparent;
                }
                input[type=file] {
                    display: none;
                }
            }
        }
    }
    a.goBackToSeaButton {
        @include labelHighlight;
        cursor: pointer;
        display: block;
        padding: $space-l;
        text-align: center;
        background: $colors-purple-500;
        color: $colors-purple-100;
        text-decoration: none;
    }
}

@media screen and (max-width: 840px) {
    .contentView.customization {
        display: flex;
        flex-direction: column;
        .header {
            padding: $space-l $space-l $space-m $space-l;
        }
        .customizationPointsBox {
            flex: 1;
            flex-direction: column;
            justify-content: space-around;
            padding: 0 0 $space-l 0;
            .customizationPoint {
                padding: 0 $space-l;
                border: none !important;
                .pickerBox {
                    justify-content: flex-start;
                    .color {
                        margin-right: $space-m;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .contentView.customization .customizationPointsBox .customizationPoint .pickerBox {
        justify-content: space-between;
        .color {
            margin-right: 0;
        }
    }
}