@font-face {
    font-family: "Leitura Display";
    font-style: italic;
    font-display: swap;
    src: url("/assets/fonts/Leitura/Display/LeituraDisplay-Italic.woff") format("woff");
}


@font-face {
    font-family: "Geomanist Medium";
    font-display: swap;
    src: url("/assets/fonts/Geomanist/Medium/Geomanist-Medium.woff2") format("woff2"),
    url("/assets/fonts/Geomanist/Medium/Geomanist-Medium.woff") format("woff");
}


@font-face {
    font-family: "Geomanist Regular";
    font-display: swap;
    src: url("/assets/fonts/Geomanist/Regular/Geomanist.woff2") format("woff2"),
    url("/assets/fonts/Geomanist/Regular/Geomanist.woff") format("woff");
}

@mixin bodyIntro
{
    font-family: "Geomanist Regular", sans-serif;
    font-size: 28px;
    line-height: 40px;
    @media screen and (max-width: 1400px) {
        font-size: 24px;
        line-height: 36px;
    }
    @media screen and (max-width: 1240px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media screen and (max-width: 1080px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 840px) {
        font-size: 20px;
        line-height: 30px;
    }
}

@mixin titleLarge
{
    font-family: "Leitura Display", serif;
    font-style: italic;
    font-weight: unset;
    font-size: 56px;
    line-height: 64px;
    @media screen and (max-width: 600px) {
        font-size: 40px;
        line-height: 48px;
    }
}

@mixin titleMedium
{
    font-family: "Leitura Display", serif;
    font-style: italic;
    font-weight: unset;
    font-size: 40px;
    line-height: 60px;
    @media screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin titleSmall
{
    font-family: "Leitura Display", serif;
    font-style: italic;
    font-weight: unset;
    font-size: 28px;
    line-height: 35px;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin titleDiscours
{
    font-family: "Leitura Display", serif;
    font-style: italic;
    font-weight: unset;
    font-size: 28px;
    line-height: 44px;
    @media screen and (max-width: 1400px) {
        font-size: 24px;
        line-height: 36px;
    }
    @media screen and (max-width: 1240px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media screen and (max-width: 1080px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 840px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 370px) {
        font-size: 14px;
        line-height: 21px;
    }
}

@mixin labelHighlight
{
    font-family: "Geomanist Medium", sans-serif;
    font-size: 24px;
    line-height: 24px;
    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 20px;
    }
}

@mixin labelBody
{
    font-family: "Geomanist Regular", sans-serif;
    font-size: 20px;
    line-height: 28px;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin labelSecondary
{
    font-family: "Geomanist Regular", sans-serif;
    font-size: 16px;
    line-height: 22px;
}


@mixin labelAction
{
    font-family: "Geomanist Medium", sans-serif;
    font-size: 18px;
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}